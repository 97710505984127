import React from "react";
import styled from "styled-components";
import Flags from "country-flag-icons/react/3x2";
import { missingLocaleMap } from "~/components/UI/LanguageSelector";
import AddButton from "~/components/UI/AddButton";
import { FormAdd, Trash } from "grommet-icons";
import EditableText from "~/components/UI/EditableText";
import { Button } from "grommet";
import EditableTextArea from "../EditableTextArea";

interface Data {
  [key: string]: {
    [key: string]: string;
  };
}

interface OutlineCustomDataProps {
  data: Data;
  setData: (data: Data) => void;
  languages: { code: string; name: string }[] | undefined;
  keys: string[];
  setShowDeleteCustomDataModal: (data: { key: string }) => void;
  flow: FlowIndexResponse;
  availableLocales: string[] | undefined;
}

const Wrapper = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 30rem;

  table {
    width: 100%;
    font-size: 16px;

    border-radius: 10px;
    border: 1px solid #ccc;

    .firstColumn {
      min-width: 100px !important;
      width: 100px;

      &.key {
        background-color: #eee;
      }

      div {
        justify-content: space-between;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .header {
      font-size: 12px;
      div {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    td {
      background-color: #fff;
      padding: 6px;
      min-width: 250px;
      vertical-align: top;
    }
  }
`;

export const OutlineCustomData: React.FC<OutlineCustomDataProps> = ({
  data,
  setData,
  languages,
  keys,
  setShowDeleteCustomDataModal,
  flow,
  availableLocales,
}) => {
  const translatedLanguages = flow?.translatedLanguages;
  return (
    <Wrapper>
      <table>
        <tr>
          <td className="firstColumn"></td>
          {translatedLanguages?.map((locale) => {
            const language = languages?.find((lang) => lang.code === locale)?.name;

            // @ts-ignore
            const Flag = Flags[missingLocaleMap[language] || locale.toUpperCase()];

            return (
              <td className="header" key={language} style={{ textTransform: "uppercase" }}>
                <div>
                  {Flag ? <Flag width={"20px"} height={"20px"} /> : ""}
                  <b>{" " + language}</b>
                </div>
              </td>
            );
          })}
        </tr>

        {keys.map((key) => {
          return (
            <tr key={key}>
              <td className="firstColumn header key" key={key}>
                <div>
                  <b>
                    {" "}
                    <EditableText
                      initialValue={key}
                      key={key}
                      startEditing={key === "new"}
                      onCommit={(_, newKey) => {
                        if (Object.keys(data["en"]).includes(newKey)) {
                          const newData = Object.assign({}, data);
                          setData(newData);
                          return;
                        }
                        const newData = Object.assign({}, data);
                        availableLocales?.forEach((l) => {
                          const value = newData[l][key];
                          delete newData[l][key];
                          newData[l][newKey] = value;
                        });
                        setData(newData);
                      }}
                      id={1}
                    />
                  </b>
                  <div>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        setShowDeleteCustomDataModal({ key: key });
                      }}
                    >
                      <Trash size="small" />
                    </Button>
                  </div>
                </div>
              </td>
              {translatedLanguages?.map((locale) => {
                return (
                  <td key={`${key}-${locale}`}>
                    <EditableTextArea
                      initialValue={data[locale] && data[locale][key] ? data[locale][key] : data["en"][key] || " "}
                      key={key}
                      onCommit={(newValue) => {
                        const newData = Object.assign({}, data);
                        newData[locale][key] = newValue;
                        setData(newData);
                      }}
                      initiallyEditing={false}
                      valueTransformer={(v) => v}
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}

        <tr>
          <td className="firstColumn key">
            <AddButton
              style={{
                marginTop: 0,
              }}
              onClick={(e) => {
                e.preventDefault();
                const newData = Object.assign({}, data);
                availableLocales?.forEach((l) => {
                  newData[l] = { ...newData[l], new: "" };
                });
                setData(newData);
              }}
            >
              <FormAdd size="small" />
              <small>Add</small>
            </AddButton>
          </td>

          {translatedLanguages?.map((locale) => {
            return <td key={`new-${locale}`}></td>;
          })}
        </tr>
      </table>
    </Wrapper>
  );
};

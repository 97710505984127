import React, { FC, useState, useEffect } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { FormAdd, Trash } from "grommet-icons";
import { Button } from "~/components/UI";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateFlow from "~/lib/requests/updateFlow";
import AddButton from "~/components/UI/AddButton";
import EditableText from "~/components/UI/EditableText";
import CodeMirror from "@uiw/react-codemirror";
import { createTheme } from "@uiw/codemirror-themes";
import DeleteCustomData from "./DeleteCustomData";
import { OutlineCustomData } from "../../FlowBuilder/Outline/OutlineCustomData";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  locale: string;
  languages: Language[] | undefined;
  appViewMode: string;
}

const Section = styled.div`
  align-items: center;
  background-color: #eee;

  border: 0px;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-family: inherit;
  font-size: 16px;
  padding-left: 8px;
  padding: 5px 10px;
  text-align: left;
  transition: box-shadow 0.2s;
  width: 100%;

  .deleteButton {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #dcdcdc;

    .deleteButton {
      opacity: 1;
      display: block;
    }
  }

  &.selected {
    background-color: #ddd;
  }

  &:active {
    box-shadow: 0px 0px 0px 0px #eee;
  }
`;

const Form = styled.form`
  font-size: 14px;
  width: 100%;
`;

const Panes = styled.div`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
`;

const LeftPane = styled.div`
  width: 150px;
  flex-shrink: 0;
  background-color: transparent;
  min-height: 300px;

  padding-right: 0px;
`;

const RightPane = styled.div`
  width: 100%;
  background-color: #eee;
  border-left: 1px solid #ccc;
  padding: 10px;
`;

const StyledTextArea = styled.div`
  height: 100%;
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
  border: 1px solid #ccc;
  border-radius: 4px;
  .CodeMirror * {
    font-family: inherit;
    font-weight: 600;
  }
`;

const codeMirrorTheme = createTheme({
  theme: "light",
  settings: {
    background: "transparent",
    lineHighlight: "transparent",
    gutterBackground: "transparent",
  },
  styles: [],
});

const ToggleButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  line-height: 0px;
  padding: 0px;

  &:hover {
    svg {
      fill: #000;
    }
  }

  &.deleteButton {
    padding: 5px;
  }
`;

const CustomContent: FC<Props> = ({ visible, setVisible, mutate, flow, locale, languages, appViewMode }) => {
  const [data, setData] = useState(flow.data);
  const keys = Object.keys(data[locale] || data["en"] || {});
  keys.sort();
  const [selectedKey, setSelectedKey] = useState(keys[0]);
  const [showDeleteCustomDataModal, setShowDeleteCustomDataModal] = useState<false | { key: string }>(false);

  const avaliableLocales = languages?.map((l) => {
    return l.code;
  });

  useEffect(() => {
    if (data[locale]) {
      if (!Object.keys(data[locale]).includes(selectedKey)) {
        setSelectedKey(keys[0]);
      }
    }
  }, [data]);

  const saveFlow = () => {
    updateFlow(flow, { data }, mutate);
  };

  const removeTrailingEmptyLines = (value: string) => {
    return value.replace(/\n+$/, "");
  };

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          saveFlow();
        }}
      >
        <Card>
          <CardHeader pad="small">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text size="small" style={{ fontWeight: 600 }}>
                Custom Data
              </Text>

              <Text size="small">
                Create custom data that is accessible in prompts using the <code>{"{{ data.YOUR_KEY_HERE }}"}</code>{" "}
                tag. <br />
                <a href="#" onClick={() => alert("Coming soon")}>
                  Learn more about tags
                </a>
              </Text>
            </div>
          </CardHeader>
          <CardBody pad={{ horizontal: "small", bottom: "small" }}>
            {appViewMode === "canvas" && (
              <Panes>
                <LeftPane>
                  {keys.map((k) => {
                    return (
                      <Section
                        key={k}
                        onClick={() => setSelectedKey(k)}
                        className={selectedKey === k ? "selected" : ""}
                      >
                        <EditableText
                          initialValue={k}
                          key={k}
                          startEditing={k === "new"}
                          onCommit={(_, newKey) => {
                            if (Object.keys(data[locale]).includes(newKey)) {
                              const newData = Object.assign({}, data);
                              setData(newData);
                              return;
                            }
                            const newData = Object.assign({}, data);
                            avaliableLocales?.forEach((l) => {
                              const value = newData[l][k];
                              delete newData[l][k];
                              newData[l][newKey] = value;
                            });
                            setData(newData);
                          }}
                          id={1}
                        />

                        <ToggleButton
                          className="deleteButton"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            setShowDeleteCustomDataModal({ key: k });
                          }}
                        >
                          <Trash size="small" />
                        </ToggleButton>
                      </Section>
                    );
                  })}

                  <AddButton
                    style={{
                      width: "100px",
                      paddingRight: "20px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const newData = Object.assign({}, data);
                      avaliableLocales?.forEach((l) => {
                        newData[l] = { ...newData[l], new: "" };
                      });
                      setData(newData);
                    }}
                  >
                    <FormAdd />
                    Add
                  </AddButton>
                </LeftPane>
                <RightPane>
                  {selectedKey && (
                    <StyledTextArea>
                      <CodeMirror
                        className="CodeMirror"
                        value={data[locale]?.[selectedKey]}
                        height="300px"
                        onChange={(value) => {
                          const newData = Object.assign({}, data);
                          newData[locale][selectedKey] = value;
                        }}
                        theme={codeMirrorTheme}
                        onBlur={(e) => {
                          let innerText = "";
                          if (e.target.className == "cm-content") {
                            innerText = e.target.innerText;
                          }

                          if (innerText !== "") {
                            const newData = Object.assign({}, data);
                            avaliableLocales?.forEach((l) => {
                              if ((newData[l] && newData[l][selectedKey] === "") || l === locale) {
                                newData[l][selectedKey] = removeTrailingEmptyLines(innerText);
                              }
                            });
                          }
                        }}
                      />
                    </StyledTextArea>
                  )}
                </RightPane>
              </Panes>
            )}
            {appViewMode === "outline" && (
              <OutlineCustomData
                data={data}
                setData={setData}
                languages={languages}
                keys={keys}
                setShowDeleteCustomDataModal={setShowDeleteCustomDataModal}
                flow={flow}
                availableLocales={avaliableLocales}
              />
            )}
          </CardBody>
          <CardFooter pad="small" background="light-2">
            <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

            <Button
              primary
              size="small"
              label="Save"
              type="submit"
              onClick={() => {
                saveFlow();
                setVisible(false);
              }}
            />
          </CardFooter>
        </Card>
      </Form>

      {showDeleteCustomDataModal && (
        <DeleteCustomData
          keyToDelete={showDeleteCustomDataModal.key}
          visible={!!showDeleteCustomDataModal}
          setVisible={setShowDeleteCustomDataModal}
          flow={flow}
          setData={setData}
          availableLocales={avaliableLocales}
        />
      )}
    </Layer>
  );
};

export default CustomContent;

/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-ignore
import SeedRandom from "./seedrandom";

export default function shuffle(array: any[], seed: any) {
  const rng = new SeedRandom(seed);

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(rng() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

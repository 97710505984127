/* eslint-disable @typescript-eslint/no-explicit-any */
const isPrimitive = (value: ChangedFlow | FlowSection) => {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    value === null ||
    value === undefined
  );
};

const getFlowKeys = (flow: any) => {
  const keysToIgnore = ["createdAt", "updatedAt", "version", "speakerBackground", "listenerBackground"];

  const flowKeys = Object.keys(flow).reduce((acc: any, key: any) => {
    if (keysToIgnore.indexOf(key) < 0) {
      acc[key] = flow[key];
    }
    if (key === "sections") {
      acc[key] = flow[key].map((section: FlowSection) => section.id);
    }
    return acc;
  }, {});

  return flowKeys;
};

/**
 * A function that takes the current flow and the updated flow and returns the changes between them
 * The changes are returned as an array of objects containing the path to the changed value, the old value and the new value
 * The objects can be different depending on the type of change
 * These changes will be used to map the correct request to the undo/redo functionality
 * @param currentFlow
 * @param updatedFlow
 * @returns An array of objects containing the path to the changed value, the old value and the new value
 */
const getFlowDifference = (currentFlow: ChangedFlow | undefined, updatedFlow: ChangedFlow) => {
  const changes: Change[] = [];
  const keysToIgnore = ["updatedAt", "audioFile", "audioLocaleMap", "speakerBackground", "listenerBackground"];

  const getFlowChange = (currentFlowChanges: any, updatedFlowChanges: any, pathArray: string[]) => {
    if (currentFlowChanges === updatedFlowChanges) {
      return;
    }

    if (
      typeof currentFlowChanges != typeof updatedFlowChanges ||
      isPrimitive(currentFlowChanges) ||
      isPrimitive(updatedFlowChanges)
    ) {
      return changes.push({
        path: pathArray,
        from: currentFlowChanges,
        to: updatedFlowChanges,
      });
    }

    const allKeys = new Set();
    Object.keys(currentFlowChanges).forEach((key: string) => allKeys.add(key));
    Object.keys(updatedFlowChanges).forEach((key: string) => allKeys.add(key));

    allKeys.forEach((key: any) => {
      if (keysToIgnore.indexOf(key) >= 0) {
        return;
      }

      if (key === "sections") {
        const currentFlowSections = currentFlowChanges.sections.map((section: FlowSection) => section.id);
        const updatedFlowSections = updatedFlowChanges.sections.map((section: FlowSection) => section.id);

        const sameOrder = currentFlowSections.every((sectionId: number, i: number) => {
          return sectionId === updatedFlowSections[i];
        });

        // section id is the id of the section that was added or removed
        let sectionId = undefined;
        if (currentFlowSections.length > updatedFlowSections.length) {
          sectionId = currentFlowSections.filter((sectionId: number) => !updatedFlowSections.includes(sectionId))[0];
        } else if (currentFlowSections.length < updatedFlowSections.length) {
          sectionId = updatedFlowSections.filter((sectionId: number) => !currentFlowSections.includes(sectionId))[0];
        }

        if (currentFlowSections.length !== updatedFlowSections.length || !sameOrder) {
          changes.push({
            path: ["ordering section"],
            from: { sections: currentFlowSections },
            to: { sections: updatedFlowSections },
            sectionId: sectionId,
          });
        } else {
          getFlowChange(currentFlowChanges[key], updatedFlowChanges[key], pathArray.concat(key));
        }
      } else if (key === "steps") {
        const currentFlowSteps = currentFlowChanges.steps.map((step: AnyStep) => step.id);
        const updatedFlowSteps = updatedFlowChanges.steps.map((step: AnyStep) => step.id);

        const sameStepOrder = currentFlowSteps.every((stepId: string, i: number) => {
          return stepId === updatedFlowSteps[i];
        });

        // step id is the id of the step that was added or removed
        let stepId = undefined;
        if (currentFlowSteps.length > updatedFlowSteps.length) {
          stepId = currentFlowSteps.filter((stepId: string) => !updatedFlowSteps.includes(stepId))[0];
        } else if (currentFlowSteps.length < updatedFlowSteps.length) {
          stepId = updatedFlowSteps.filter((stepId: string) => !currentFlowSteps.includes(stepId))[0];
        }

        if (currentFlowSteps.length !== updatedFlowSteps.length || !sameStepOrder) {
          changes.push({
            path: ["ordering step"],
            from: { steps: currentFlowSteps },
            to: { steps: updatedFlowSteps },
            stepId: stepId,
            sectionId: currentFlowChanges.id,
          });
        } else {
          getFlowChange(currentFlowChanges[key], updatedFlowChanges[key], pathArray.concat(key));
        }
      } else {
        getFlowChange(currentFlowChanges[key], updatedFlowChanges[key], pathArray.concat(key));
      }
    });
  };

  getFlowChange(currentFlow, updatedFlow, []);

  changes.forEach((change: Change) => {
    if (change.path.indexOf("sections") >= 0) {
      const sectionIndex = Number(change.path[1]);
      const key = change.path[2] as keyof FlowSection;

      change["type"] = "section";
      change["sectionId"] = updatedFlow.sections[sectionIndex].id;
      change["to"] = {
        [key]: updatedFlow.sections[sectionIndex][key],
      };
      change["from"] = {
        [key]: currentFlow?.sections[sectionIndex][key],
      };
    }
    if (change.path.indexOf("steps") >= 0) {
      const sectionIndex = Number(change.path[1]);
      const stepIndex = Number(change.path[3]);
      const key = change.path[4] as keyof AnyStep;
      const newStepValue = {
        [key]: updatedFlow.sections[sectionIndex].steps[stepIndex][key],
      };
      change["type"] = "step";
      change["stepId"] = updatedFlow.sections[sectionIndex].steps[stepIndex].id;
      change["to"] = newStepValue;
      change["from"] = {
        [key]: currentFlow?.sections[sectionIndex].steps[stepIndex][key],
      };
    }
    if (change.path.indexOf("ordering section") >= 0) {
      change["type"] = "flow";
    }
    if (change.path.indexOf("ordering step") >= 0) {
      change["type"] = "section";
    }

    if (!change.type && currentFlow !== undefined && updatedFlow !== undefined) {
      const newFlowValue = getFlowKeys(updatedFlow);
      const oldFlowValue = getFlowKeys(currentFlow);

      change["type"] = "flow";
      change["to"] = newFlowValue;
      change["from"] = oldFlowValue;
    }
  });
  return changes;
};

const isEqual = (currentFlow: FlowIndexResponse, updatedFlow: FlowIndexResponse | Change[]): boolean => {
  if (currentFlow === updatedFlow) {
    return true;
  }

  if (typeof currentFlow != typeof updatedFlow || isPrimitive(currentFlow)) {
    return false;
  }

  const allKeys: string[] = [];
  Object.keys(currentFlow).forEach((key: string) => {
    if (allKeys.indexOf(key) < 0) {
      allKeys.push(key);
    }
  });

  Object.keys(updatedFlow).forEach((key: string) => {
    if (allKeys.indexOf(key) < 0) {
      allKeys.push(key);
    }
  });

  for (const key of allKeys) {
    // @ts-ignore
    if (isEqual(currentFlow[key], updatedFlow[key])) {
      return false;
    }
  }
  return true;
};

export { getFlowDifference, isEqual };
